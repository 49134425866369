/* Basic Styles:
    LIGHT           --- home-light / section-title-light / section-subtitle-light / light-button
    DARK            --- home-dark  / section-title-dark  / section-subtitle-dark  / dark-button
    HIGHLIGHT       --- highlight
    MENU            --- menu
    TABLE           --- styled-table
    INPUT (radios)  --- same-line-inputs
    FORM (divs)     --- form-group
    SOCIAL (icons)  --- social
 */

/* Basics, based on tags */
body {
  font-family: Source Sans Pro, sans-serif;
  font-size: 1.25rem;
  line-height: 1.5em;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
footer,
header,
section,
main {
  display: block;
}
a {
  color: #bd8b13; /* Cal Poly Gold */
  background-color: transparent;
  display: inline-block;
}
a:active,
a:hover {
  outline-width: 0;
}
img {
  border-style: none;
  max-width: 100%;
}
svg:not(:root) {
  overflow: hidden;
}
input,
select,
option {
  padding: 0.5em;
  font-size: 1rem;
  background-color: #ffffff;
  outline: none;
  width: 75%;
}
table {
  table-layout: fixed;
  width: 100%;
}
td {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  width: 100%;
  word-wrap: break-word;
}
tr.spacer td {
  border: none;
}
*,
:after,
:before {
  box-sizing: border-box;
}
::-webkit-input-placeholder {
  color: rgb(131, 131, 131);
}
::-moz-placeholder {
  color: rgb(131, 131, 131);
}
:-ms-input-placeholder {
  color: rgb(131, 131, 131);
}
.site-header {
  position: relative;
  background-color: #000;
  background-size: cover;
  padding: 0;
  margin: 0;
}
@media screen and (min-width: 75em) {
  .site-header-nomenu:after {
    background: -webkit-linear-gradient(top, transparent, rgba(21, 71, 52, 0.85) 99%, rgba(21, 71, 54, 0.85));
    background: linear-gradient(180deg, transparent 0, rgba(21, 71, 52, 0.85) 99%, rgba(21, 71, 54, 0.85));
    content: "";
    height: 3rem;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

/* Define light/dark blocks */
.home-dark {
  background: #f8f8f7; /* Light Gray */
  color: #000000;
  padding-top: 3rem;
  padding-bottom: 2rem;
}
.home-light {
  background: #fff;
  color: #54585a; /* Cal Poly Dark Gray */
  padding-top: 3rem;
  padding-bottom: 2rem;
}
.home-header {
  background: #154734; /* Cal Poly Green */
  color: #fff;
  padding-top: 3rem;
  padding-bottom: 2rem;
  text-align: center;
}
.home-footer {
  background: #154734; /* Cal Poly Green */
  color: #fff;
  padding-top: 3rem;
  padding-bottom: 2rem;
  text-align: center;
}

/* ALL title/subtitle styling */
.section-title-dark {
  font-family: abolition, sans-serif;
  font-weight: normal;
  letter-spacing: 0.05em;
  font-size: 3rem;
  color: #bd8b13; /* Cal Poly Gold */
  line-height: 1em;
  margin: 0;
  text-align: center;
}
.section-subtitle-dark {
  font-family: Source Sans Pro, sans-serif;
  font-weight: bold;
  font-size: 2rem;
  color: #154734; /* Cal Poly Green */
  line-height: 0.75em;
}
.section-title-light {
  font-family: abolition, sans-serif;
  font-weight: normal;
  letter-spacing: 0.025em;
  font-size: 3rem;
  color: #154734; /* Cal Poly Green */
  line-height: 1em;
  margin: 0;
  text-align: center;
}
.section-subtitle-light {
  font-family: Source Sans Pro, sans-serif;
  font-weight: bold;
  font-size: 2rem;
  color: #c69214; /* Cal Poly Gold */
  line-height: 1em;
}
@media screen and (min-width: 40em) {
  .section-title-dark,
  .section-title-light {
    font-size: 4rem;
  }
  .section-subtitle-dark,
  .section-subtitle-light {
    font-size: 2rem;
    line-height: 1em;
  }
}
@media screen and (min-width: 64em) {
  .section-title-dark,
  .section-title-light {
    font-size: 4rem;
  }
  .section-subtitle-dark,
  .section-subtitle-light {
    font-size: 1.5rem;
    line-height: 0.75em;
  }
}

/* Define file input sections */
.file-input {
  border-radius: 1em 1em;
  color: #000;
  background-color: #d4d4d4;
}

/* Define light/dark buttons */
.dark-button,
.light-button,
.cancel-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  appearance: none;
  text-decoration: none;
  font-family: Source Sans Pro, sans-serif;
  text-transform: uppercase;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem 1.5rem;
  transition-duration: 0.4s;
}
.dark-button,
.light-button {
  background-color: #3a913f; /* Cal Poly Farmer's Market */
  color: #ffffff;
}
.cancel-button {
  background-color: #9e9e9e; /* Light Grey */
  color: #ffffff;
}
.dark-button:hover,
.light-button:hover {
  background-color: #2b6b2f; /* Cal Poly Farmer's Market */
}
.cancel-button:hover {
  background-color: #54585a; /* Cal Poly Dark Grey */
}
@media screen and (min-width: 85em) {
  .dark-button,
  .light-button {
    font-size: 1rem;
  }
}

/* Change font color for highlight effect (see CCIC PreQual page) */
.highlight {
  color: #c69214; /* Cal Poly Gold */
}
/* Change font color for highlight effect (see CCIC PreQual page) */
.highlight-dark {
  color: #154734; /* Cal Poly Gold */
}

/* Ensures proper styling for menu bar (see CCIC pages) */
a.menu {
  color: white;
  -webkit-text-decoration-style: none;
  text-decoration-style: none;
  text-decoration: none;
  font-weight: bold;
}

/* Tables with styling, such as borders/shading (see CCIC Scoreboard page) */
.styled-table {
  border: 2px solid #154734; /* Cal Poly Green */
}
.styled-table th {
  background-color: #f2f2f2; /* LIGHTER Gray */
  padding: 0.5em;
  font-size: 1em;
}
.styled-table tr:nth-child(even) {
  background-color: #f2f2f2; /* LIGHTER Gray */
}

/* Allow for radio inputs to be on the same line
   Example: "...question... ()YES ()NO" (see CCIC PreQual page) */
.same-line-inputs {
  width: auto;
}

/* To use for <div/>s for grouping questions/content within a <form/> (see CCIC PreQual page) */
.form-group {
  padding: 1rem;
}

/* Not all text is center aligned, but if needed use this class */
.center-align {
  text-align: center;
}

.left-align {
  text-align-last: left;
}

.right-align {
  text-align: right;
}

.datetime-background {
  background-color: #fff;
}

.ninjio-wrapper {
  margin: 0 auto;
  padding-bottom: 2em;
}

.ninjio-video {
  display: block;
  margin: 0 auto;
  border-style: none;
  overflow: hidden;
  background-color: #000;
}

.credits-wrap {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: space-evenly;
}

.credits-list ul {
  padding: 0;
  flex-basis: 0;
}

.credits-list li {
  margin-bottom: 10px;
  list-style: none;
  flex-grow: 1;
}

/* Fancy social icons styling for line with hover movement (see Footer) */
a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}
a.social:hover {
  transform: translateY(-2px);
}
